export const activityConstants = {
  SAVE_INTERAL_USERS_TO_SIGN: "SAVE_INTERAL_USERS_TO_SIGN",
  SAVE_INTERAL_USERS_NOT_SIGN: "SAVE_INTERAL_USERS_NOT_SIGN",
  SAVE_SIGNERS: "SAVE_SIGNERS",
  SAVE_TEMPORAL_SIGNERS: "SAVE_TEMPORAL_SIGNERS",
  SAVE_TEMPORAL_NO_SIGNERS: "SAVE_TEMPORAL_NO_SIGNERS",
  SAVE_TEMPORAL_USERS_TO_SIGN: "SAVE_TEMPORAL_USERS_TO_SIGN",
  SAVE_TEMPORAL_NOT_TO_SIGN: "SAVE_TEMPORAL_NOT_TO_SIGN",
  VALIDATE_TEMPORAL_USER: "VALIDATE_TEMPORAL_USER",
  VALIDATE_TEMPORAL_USER_NAME: "VALIDATE_TEMPORAL_USER_NAME",
  VALIDATE_TEMPORAL_USER_RFC: "VALIDATE_TEMPORAL_USER_RFC",
  RESTART: "RESTART",
  SET_SIGNATURE_TYPE: "SET_SIGNATURE_TYPE",
  SAVE_NO_SIGNERS: "SAVE_NO_SIGNERS",
  SET_ALL_SIGNERS: "SET_ALL_SIGNERS",
  DELETE_TEMPORAL_SIGNER: "DELETE_TEMPORAL_SIGNER",
  DELETE_TEMPORAL_NO_SIGNER: "DELETE_TEMPORAL_NO_SIGNER",
  DELETE_ERROR: "DELETE_ERROR",
}