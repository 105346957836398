import React from 'react';
import { Dialog, Grid, Button, Tooltip } from '@material-ui/core';
import { isMobile, isMobileOnly } from 'axeleratum-sgc-frontend-library';
import { Field, reduxForm } from 'redux-form';
import {
  RenderTextField,
  RenderMultiSelect,
  RenderDateField,
} from 'axeleratum-sgc-frontend-library';
import { UserHttp } from '../../core/http/user.http';
import DropzoneFiles from './dropzone-files';
import { DocumentsHttp } from '../../core/http/documents.http';
import { CollaboratorsHttp } from '../../core/http/collaborators.http';
import { validatePermissions, permissionScheme } from '../../core/utils/can-i';
import TemplateUsage from '../templates/template-usage';
import { tokenExpired } from '../../core/utils';
import { authActions } from '../../core/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';

class DialogFormContract extends React.Component {
  state = {
    usersSelect: [],
    disableName: false,
    openTemplateUsage: false,
    template: null,
    documentInfo: null,
    enableEdit: false,
    showEditTemplate: false,
  };
  userHttp = new UserHttp();
  documentsHttp = new DocumentsHttp();
  collaborators = new CollaboratorsHttp();

  getPermissions() {
    // debugger;
    validatePermissions(permissionScheme.documentManagement.inviteToSign).then(
      (enabled) => {
        this.setState({ inviteToSignIsEnabled: enabled });
      }
    );
  }

  componentDidMount() {
    const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    } 
    const { documentSelected, typeForm } = this.props;
    this.getUsersSelect();
    this.getPermissions();

    if (typeForm === 'update' && documentSelected !== null) {
      this.validateDocumentUpdate(documentSelected.id);

      if (documentSelected.hasTemplate) {
        this.getTemplate(documentSelected.templateId);
      }
      

      this.documentsHttp.getDocumentInfo(documentSelected.id, document => {
        // debugger;
        const documentInfo = {
          id: document._id ? document._id : document.id,
          name: document.Name ? document.Name : document.name ,
          description: document.Description ? document.Description : (document.description ? document.description : 'SIN DESCRIPCION'),
          tags: document.Tags ? document.Tags : (document.tags ? document.tags : []),
          path: document.Path ? document.Path : (document.path ? document.path : ''),
          folderId: document.FolderId ? document.FolderId : document.folderId,
          extension: document.Extension ? document.Extension : document.extension ,
          templateId: document.TemplateId ? document.TemplateId : document.templateId ,
          companyId: document.CompanyId ? document.CompanyId : document.companyId
        };

        if(document.StatusBpm === "Preparar Revisión"){
          this.setState({showEditTemplate:true})
        }
        
        this.props.initialize({ 
          name: documentInfo.name,
          description: documentInfo.description,
          tags: documentInfo.tags.map(tag => {
              if (tag !== ''){
                return {label: tag, value: tag}
              } else {return null}
            }),
          users: this.props.collaborators ? this.props.collaborators : [],
          endDate: new Date().toISOString().substring(0, 10),
        });

        this.setState({documentInfo: documentInfo});
      }, () => {})
    } else {
      this.props.initialize({
        endDate: new Date().toISOString().substring(0, 10),
      });
    }
  }

  getUsersSelect = () => {
    /* this.collaborators
      .getUsersCollaboratorsByCompany(this.props.company.id)
      .then(({ data }) => {
        const users = [];
        data.forEach((user) => {
          users.push({
            label: user.completeName,
            value: user.id,
            email: user.email,
          });
        });

        this.setState({ usersSelect: users });
      })
      .catch(() => {}); */
    
      const {isLoggedIn, userLoggedOut} = this.props;  

      if(isLoggedIn && tokenExpired()){
           userLoggedOut()
           return
         } 
    this.userHttp.findAll((resp) => {
      const users = [];
      resp.forEach(user => {
        if (!user.deleted && user.enabled && user && (!user.externalProvider)) {
          const isMember = user.companies.some(item => item.id === this.props.company.id) || user.roles.some(rol => rol.allCompanies);
          
          if (!isMember) {
            users.push({
              label: user.completeName,
              value: user.id,
              email: user.email,
            });
          }
        }
      });

      this.setState({ usersSelect: users });
    }, (error) => {}
    )
  }

  getTemplate(templateId) {
    const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    } 
    this.documentsHttp.getDocumentInfo(templateId, resp => {
      // debugger;
      const template = {
        id: resp ? resp._id : null,
        name: resp ? resp.Name : '' ,
        description: resp ? resp.Description : 'SIN DESCRIPCION 2',
        tags: resp.Tags,
        documentTypeId: resp ? resp.DocumentTypeId : '',
        documentType: resp ? resp.DocumentType.Name : '',
        ownerId: resp ? resp.Owner : '',
        nameDisplay: resp ? resp.NameDisplay : '',
        extension: resp ? resp.Extension : ''
      };

      this.setState({ template: template });
    }, () => {});
  }

  validateDocumentUpdate(documentId) {
    const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    } 
    this.documentsHttp.validateDocumentUpdate(documentId, resp => {
      this.setState({enableEdit: resp})
    }, () => {});
  }

  render() {
    const {
      documentSelected,
      openDialog,
      submitActions,
      onCancel,
      tagsSelect,
      handleSubmit,
      typeForm,
    } = this.props;
    const { usersSelect, disableName, openTemplateUsage, template, documentInfo, enableEdit, showEditTemplate } = this.state;

    return (
      <Dialog className={isMobile ? '' : 'w-50-dialog'} open={openDialog}>
        <form className={isMobileOnly ? 'p-3' : 'p-5'} onSubmit={handleSubmit(submitActions)}>
          <Grid container>
            <Grid item xs={12}>
              <h3>{typeForm === 'update' ? 'Editar' : 'Crear'} Contrato</h3>
              <Field
                label='Nombre del contrato'
                name='name'
                component={RenderTextField}
                disabled={disableName}
              />

              <Field
                label='Descripción'
                name='description'
                component={RenderTextField}
              />

              <Field
                label='Fecha de vencimiento'
                name='endDate'
                component={RenderDateField}
              />

              <Field
                label='Seleccionar etiquetas'
                name='tags'
                options={tagsSelect}
                component={RenderMultiSelect}
              />
            </Grid>

            {/* {!!this.state.inviteToSignIsEnabled && (
              <Grid item xs={12}>
                <h3>Invitar Usuarios</h3>

                <Field
                  label='Seleccionar usuarios'
                  name='users'
                  options={usersSelect}
                  component={RenderMultiSelect}
                />
              </Grid>
            )} */}

            { (typeForm !== 'update') &&
              <Grid item xs={12}>
                <h3>Adjuntar Archivos</h3>
                <Field
                  name={'file'}
                  component={DropzoneFiles}
                  acceptedFiles={'application/pdf'}
                  onChange={(file) => {}}
                />
              </Grid>
            }

            { (typeForm === 'update' && (documentSelected && documentSelected.hasTemplate)) &&
              <Grid item xs={12}>
                <h3>Contenido de documento</h3>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => this.setState({openTemplateUsage: true})}
                  disabled={!template || !documentInfo || !enableEdit || !showEditTemplate}
                >
                  Editar contenido
                </Button>
              </Grid>
            }

            { (openTemplateUsage && template) &&
              <TemplateUsage
                business={this.props.company}
                currentUser={this.props.currentUser}
                templateSelected = {template}
                documentSelected={documentInfo}
                open={openTemplateUsage}
                onClose={() => {this.setState({openTemplateUsage: false})}}
              />
            }

            <Grid className='mt-3' container justify='flex-end'>
              {isMobile ? (
                <React.Fragment>
                  <Grid item>
                    <Button
                      type='button'
                      variant='outlined'
                      color='secondary'
                      onClick={() => onCancel()}
                    >
                      Cancelar
                    </Button>
                  </Grid>

                  <Grid item>
                  <Tooltip
                      title={this.props.typeForm === 'update' && !enableEdit
                        ? 'El documento no se puede editar porque está en proceso de revisión ó firma' : ''
                      }
                    >
                      <span>
                        <Button
                          className='ml-2'
                          type='submit'
                          variant='contained'
                          color='primary'
                          disabled={this.props.typeForm === 'update' && !enableEdit}
                        >
                          Crear
                        </Button>
                      </span>
                    </Tooltip>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    type='button'
                    variant='outlined'
                    color='secondary'
                    onClick={() => onCancel()}
                  >
                    Cancelar
                  </Button>

                  <Tooltip
                    title={this.props.typeForm === 'update' && !enableEdit
                      ? 'El documento no se puede editar porque está en proceso de revisión ó firma' : ''
                    }
                  >
                    <span>
                      <Button
                        className='ml-2'
                        type='submit'
                        variant='contained'
                        color='primary'
                        disabled={this.props.typeForm === 'update' && !enableEdit}
                      >
                        Guardar
                      </Button>
                    </span>
                  </Tooltip>
                </React.Fragment>
              )}
            </Grid>
          </Grid>
        </form>
      </Dialog>
    );
  }
}

const validate = (values) => {
  const errors = {
    name: ''
  }

  if (!values.name) {
    errors.name = 'El nombre del contrato es requerido';
  }

  if (values.name) {
    if (values.name > 100) {
      errors.name = 'El nombre del contrato no puede ser mayor a 100 caracteres';
    }
  }

  if (!values.endDate) {
    errors.endDate = 'La fecha de vencimiento es requerida';
  }

  return errors;
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}

 

export default compose(
connect(mapStateToProps, mapDispatchToProps),
reduxForm({
  form: 'DialogFormContract',
  validate
})
)
(DialogFormContract);
