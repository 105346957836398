import {TableRow, TableCell, Grid, Button, Avatar, Box, TextField, InputAdornment, Tooltip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

export const TableRowAproval = (
        {
            documentName, userName, classes, userColor, statusReview, showAprovalButtons, 
            aprovalTurn, reviewerName, onClickAprove, onClickReject, allowComments, comments,
            onBlurComments, dateReview
        }
    ) => {

  return (
    <TableRow>
        <TableCell component="th" scope="row">
            {!!documentName ? <div>{documentName}</div> : <p></p>}
        </TableCell>
        <TableCell align="center">
            <Box component="span">
            <Tooltip title={userName}>
                <Avatar
                    className={classes.avatar}
                    style={{backgroundColor: `${userColor ? userColor : ''}`}}
                >
                    {userName && userName.toUpperCase().charAt(0)}
                </Avatar>
            </Tooltip>
            </Box>
        </TableCell>
        <TableCell style={{textAlign: 'center'}}>
            { 
                (statusReview === null) ?
                    <Grid item container justify="center">
                        {
                            showAprovalButtons && <>
                                <Tooltip title={!aprovalTurn ? reviewerName : ''}>
                                    <span>
                                        <Button
                                            variant="contained"
                                            className={classes.btnAccept}
                                            disabled={!aprovalTurn}
                                            onClick={onClickAprove}
                                        >
                                        Aprobar
                                        </Button>
                                    </span>
                                </Tooltip>
                                <Tooltip  ttitle={!aprovalTurn ? reviewerName : ''}>
                                    <span>
                                        <Button
                                            variant="contained"
                                            className={classes.btnCancel}
                                            disabled={!aprovalTurn}
                                            onClick={onClickReject}
                                        >
                                        Rechazar
                                        </Button>
                                    </span>
                                </Tooltip>
                            </>
                        }
                    </Grid>
                : statusReview ?
                    <Box color="success.main">
                        <Tooltip title="Aprobado">
                        <CheckCircleIcon/>
                        </Tooltip>
                    </Box>
                :
                    <Box color="error.main">
                        <Tooltip title="Rechazado">
                        <CancelIcon/>
                        </Tooltip>
                    </Box>
            }
        </TableCell>
        <TableCell>
            { 
                allowComments ?
                    <TextField
                        multiline
                        fullWidth
                        defaultValue={comments}
                        variant="outlined"
                        size="small"
                        rowsMax={3}
                        inputProps={{
                            className: classes.textFieldComments 
                        }}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" style={{paddingRight: '8px'}}>
                            <EditIcon />
                            </InputAdornment>
                        ),
                        }}
                        onBlur={onBlurComments}
                    />
                : comments
            }
        </TableCell>
        <TableCell align="center">
            {dateReview}
        </TableCell>
    </TableRow>
  )
}
