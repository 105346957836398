export const menuOptions = {
	editBusinessMgmtOption: {
		value: "businessMgmt",
		label: "Editar Gobierno Corporativo",
		active: true,
		disabled: false,
	},
	createBusinessMgmtOption: {
		value: "businessMgmt",
		label: "Crear Gobierno Corporativo",
		active: true,
		disabled: false,
	},
	updateOption: {
		value: "update",
		label: "Editar",
		active: true,
		disabled: false,
	},
	moveOption: {
		value: "move",
		label: "Mover",
		active: true,
		disabled: false,
	},

	deleteOption: {
		value: "delete",
		label: "Eliminar",
		active: true,
		disabled: false,
	},

	restoreOption: {
		value: "restore",
		label: "Restaurar",
		active: true,
		disabled: false,
	},

	openDocumentOption: {
		value: "open",
		label: "Abrir documento",
		active: true,
		disabled: false,
	},

	newDocumentOption: {
		value: "document",
		label: "Subir Documento",
		disabled: false,
	},
	newFolderOption: {
		value: "directory",
		label: "Folder",
		disabled: false,
	},

	createSignActivityOption: {
		value: "signActivity",
		label: "Crear actividad de Firma",
		active: true,
		disabled: false,
	},

	signatureStatusOption: {
		value: "signatureStatus",
		label: "Estatus de la Firma",
		active: true,
		disabled: false,
	},

	scannedCopyOption: {
		value: "scannedCopy",
		label: "Copia escaneada",
		active: true,
		disabled: false,
	},
	//folders
	editFolder: {
		value: "updateFolder",
		label: "Editar",
		disabled: false,
	},
	moveFolder: {
		value: "move",
		label: "Mover",
		disabled: false,
	},
	deleteFolder: {
		value: "deleteFolder",
		label: "Eliminar",
		disabled: false,
	}
}


export const menuItems = [
	menuOptions.updateOption,
	menuOptions.moveOption,
	menuOptions.deleteOption,
	menuOptions.restoreOption,
];

export const menuAdd = [
	menuOptions.newDocumentOption,
	menuOptions.newFolderOption,
];

export const menuAddSubFolder = [
	{
		value: "document",
		label: "Subir documento",
		disabled: false,
	},
	{
		value: "contract",
		label: "Subir documento",
		disabled: false,
	},
	{
		value: "directory",
		label: "Folder",
		disabled: false,
	},
];

export const menuFolder = [
	menuOptions.editFolder,
	menuOptions.moveFolder,
	menuOptions.deleteFolder,
];

export const createGC = {
	value: "altaGobiernoCorporativo",
	label: "Alta Gobierno Corporativo",
	disabled: false,
}


export const createSignatureOptions = {
	signActivity: menuOptions.createSignActivityOption,
	signatureStatus: menuOptions.signatureStatusOption,
};

export const documentTransactions = {
	scannedCopy: menuOptions.scannedCopyOption,
};

export const createReviewOptions = {
	immediatelyRevision: {
		value: "immediatelyRevision",
		label: "Revisión única inmediata",
		active: true,
		disabled: false,
	},
	revisionActivity: {
		value: "revisionActivity",
		label: "Crear actividad de Revisión",
		active: true,
		disabled: false,
	},
	revisionStatus: {
		value: "result_revision",
		label: "Resultado de la revisión",
		active: true,
		disabled: false,
	},
};

export const openDocumentOption = {
	value: "open",
	label: "Abrir documento",
	active: true,
	disabled: false,
};