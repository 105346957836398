import React, { Component } from 'react';
import { Dialog, DialogTitle, DialogContent, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, Grid, Button, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import AlertInfo from "@material-ui/lab/Alert";
import { ReviewersHttp } from '../../core/http/reviewers.http';
import { AlertConfirm, Alert } from 'axeleratum-sgc-frontend-library';
import './../../core/utils/string';
import { sortObjectsByValue, tokenExpired } from '../../core/utils';
import { connect } from 'react-redux';
import { TableRowAproval } from './TableRowAproval';
import { CalendarHttp } from '../../core/http/calendar.http';
import moment from "moment";
import { authActions } from '../../core/actions';

const styles = (theme) => ({
  root: {
    fontFamily: 'Open Sans, sans-serif !important'
  },
  table: {
    minWidth: '980px'
  },
  tableHeader: {
    fontWeight: '600'
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: '0.75rem',
    margin: 'auto !important'
  },
  btnAccept: {
    background: '#38B349',
    color: '#FFF',
    '&:hover': {
      background: '#277D33'
    }
  },
  btnCancel: {
    background: '#F90D38',
    color: '#FFF',
    '&:hover': {
      background: '#AE0927'
    },
    marginLeft: '8px'
  },
  textFieldComments: {
    padding: '8px 0px 8px 8px'
  }
});

class DocumentReview extends Component {

  reviewerHttp = new ReviewersHttp();
  calendarHttp = new CalendarHttp();

  constructor(props) {
    super(props);

    this.state = {
      reviewers: null,
      rowSelected: null,
      statusSelected: null,
      openAlertStatus: false,
      alertError: false, loading: false,
      documentName: null,
      comments: '',
    };
  }

  componentDidMount() {
    if (!this.props.immediatelyRevision) {
      this.getReviewers();
    }
  }

  getReviewers() {
    const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    }  

  

 

    const document = this.props.document;
    this.reviewerHttp.getReviewersByDocument(document.id, (resp) => {
      this.setState({
        documentName: resp.documentName,
        reviewers: resp && resp.Users.length > 0
          ? sortObjectsByValue(resp.Users, resp.documentOrder ? 'userOrder' : 'username')
          : [],
        loading: false
      });
      if (this.props.finishRequest) {
        this.props.finishRequest();
      }
    }, (error) => {
      this.setState({ reviewers: [], loading: false });
      if (this.props.handleFailGetReview) {
        this.props.handleFailGetReview();
      }
    });
  }

  saveReview(review) {
    const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    }  

    this.reviewerHttp.saveReview(review, (resp) => {
      this.getReviewers();
    }, (error) => {
      this.getReviewers();
    });
  }

  updateReview(review) {
    const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    }  

    this.reviewerHttp.updateReview(review, (resp) => {
      this.getReviewers();
    }, (error) => {
      this.getReviewers();
    });
  }

  onCommentsChange(evt, currentReview) {
    if (currentReview.documentReviewerId === null) {
      const review = {
        documentId: this.props.document.id,
        userId: this.props.user.userId,
        completeName: currentReview.username,
        statusReview: null,
        comments: evt.target.value
      };
      this.saveReview(review);
    } else if (evt.target.value !== currentReview.comments) {
      const review = {
        documentId: this.props.document.id,
        userId: currentReview.userId,
        completeName: currentReview.username,
        statusReview: currentReview.statusReview,
        comments: evt.target.value
      };
      this.updateReview(review);
    }
  }

  getTurnReviewer() {
    const reviewer = this.state.reviewers.find(item => item.turn);

    return `Es turno de ${reviewer.username}`;
  }

  onSubmit() {
    const { statusSelected, rowSelected } = this.state;
    const review = {
      documentId: this.props.document.id,
      userId: rowSelected.userId,
      completeName: rowSelected.username,
      statusReview: statusSelected,
      comments: rowSelected ? rowSelected.comments : ''
    };

    this.setState({ openAlertStatus: false });

    if (rowSelected.documentReviewerId === null) {
      this.saveReview(review);
    } else {
      this.updateReview(review);
    }
  }

  onSubmitImmediatelyRevision() {

    const { document, user, closeModal } = this.props;

    const { id, name } = document;
    const { completename, userId, user_name, rfc, color } = user;

    const { statusSelected, comments } = this.state;
    const review = {
      documentId: document.id,
      userId: userId,
      completeName: completename,
      statusReview: statusSelected,
      comments,
    };

    this.setState({ openAlertStatus: false });

    const reviewActivityData = {
      id: "",
      type: "revision",
      name: "Revision unica inmediata",
      description: "Revision unica inmediata",
      tags: [],
      hour: moment(new Date()).format("HH:MM"),
      date: moment(new Date()).format("YYYY-MM-DD"),
      limitDate: moment(new Date()).format("YYYY-MM-DD"),
      recordatory: 0,
      documentsNew: [],
      documentsExist: [{
        documentId: id,
        documentName: name,
        reviewers: [{
          color: !!color ? color : "#2196F3",
          email: user_name,
          userId,
          name: completename,
          order: 0,
          rfc: !!rfc ? rfc : "",
          type: "email",
          temporal: false,
        }],
        stickers: null,
        withOrder: false,
      }],
      documents: [],
    };

    const {isLoggedIn, userLoggedOut} = this.props;  

 if(isLoggedIn && tokenExpired()){
      userLoggedOut()
      return
    }  


    this.calendarHttp.createActivity(reviewActivityData)
      .then(response => this.saveReview(review))
      .then(reviewed => { closeModal() })
      .catch(error => {
        this.setState({
          alertError: true,
        })
        console.error(error);
      })

    this.setState({ openAlertStatus: false });

  }


  render() {
    const {
      openDialog,
      onClose,
      user,
      classes,
      immediatelyRevision,
      document
    } = this.props;

    const {
      documentName,
      reviewers,
      statusSelected,
      openAlertStatus,
      alertError,
      loading,
      comments
    } = this.state;

    const Loader = (
      <TableRow>
        <TableCell component="th" colSpan={5} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </TableCell>
      </TableRow>
    )


    return (
      <React.Fragment>
        <Dialog open={openDialog} fullWidth={true} maxWidth="md" className={classes.root}>
          <DialogTitle id="customized-dialog-title">
            Revisión del documento
          </DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead className={classes.tableHeader}>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>Nombre</TableCell>
                    <TableCell align="center" className={classes.tableHeader} style={{ width: '90px' }}>Validador</TableCell>
                    <TableCell align="center" className={classes.tableHeader} style={{ width: '255px', textAlign: 'center' }}>Estatus</TableCell>
                    <TableCell className={classes.tableHeader} style={{ width: '250px' }}>Comentarios</TableCell>
                    <TableCell align="center" className={classes.tableHeader} style={{ width: '110px' }}>Fecha revisión</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    immediatelyRevision
                      ? <TableRowAproval
                        documentName={document.name}
                        statusReview={null}
                        userName={user.completename}
                        classes={classes}
                        userColor={'#2196F3'}
                        showAprovalButtons
                        aprovalTurn
                        reviewerName={user.completename}
                        onClickAprove={() => {
                          this.setState({
                            openAlertStatus: true,
                            statusSelected: true,
                            rowSelected: {
                              userId: user.userId,
                              username: user.completename,
                              comments
                            }
                          })
                        }}
                        onClickReject={() => {
                          this.setState({
                            openAlertStatus: true,
                            statusSelected: false,
                            rowSelected: {}
                          })
                        }}
                        allowComments
                        comments={comments}
                        onBlurComments={(evt) => { this.setState({ ...this.state, comments: evt.target.value }) }}
                        dateReview={''}
                      />
                      : (reviewers && reviewers.length > 0) &&
                      reviewers.map((row, index) => (
                        (loading && row.userId === user.userId)
                          ? <Loader key={row.username} />
                          :
                          <TableRowAproval
                            key={row.username}
                            documentName={index === 0 && documentName}
                            userName={row.username}
                            classes={classes}
                            userColor={row.userColor}
                            statusReview={row.statusReview}
                            showAprovalButtons={row.userId === user.userId}
                            aprovalTurn={row.turn}
                            reviewerName={!row.turn ? this.getTurnReviewer() : ''}
                            onClickAprove={() => { this.setState({ openAlertStatus: true, statusSelected: true, rowSelected: row }) }}
                            onClickReject={() => { this.setState({ openAlertStatus: true, statusSelected: false, rowSelected: row }) }}
                            allowComments={row.userId === user.userId}
                            comments={row.comments}
                            onBlurComments={(evt) => { this.onCommentsChange(evt, row) }}
                            dateReview={row.dateReview !== null ? row.dateReview.getDateDDMMYYYY() : ''}
                          />
                      ))
                  }

                  {
                    (!reviewers && !immediatelyRevision) &&
                    <TableRow>
                      <TableCell colSpan={5}>
                        <AlertInfo severity="info">Cargando revisores...</AlertInfo>
                      </TableCell>
                    </TableRow>
                  }

                  {
                    ((reviewers && reviewers.length === 0) && !immediatelyRevision) &&
                    <TableRow>
                      <TableCell colSpan={5}>
                        <AlertInfo severity="info">Sin revisores</AlertInfo>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container item className="mt-3" xs={12} justify="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={() => onClose()}
              >
                Cerrar
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>


        <AlertConfirm
          open={openAlertStatus}
          onCancel={() => { this.setState({ openAlertStatus: false, statusSelected: null }) }}
          onConfirm={() => immediatelyRevision ? this.onSubmitImmediatelyRevision() : this.onSubmit()}
          textContent={`Una vez ${statusSelected ? 'aprobado' : 'rechazado'} el documento, el estatus no podrá ser cambiado. ¿Deseas continuar?`}
        />

        <Alert
          open={alertError}
          title={'Lo sentimos, hubo un error al guardar. Intenta más tarde.'}
          type="error"
          onConfirm={() => {
            this.setState({ alertError: false })
            onClose()
          }}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.authReducer.currentUser,
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DocumentReview));



