import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { PropTypes } from 'prop-types';

export const AlertConfirm = (props) => {
  const { open, title, textContent, onConfirm, onCancel } = props;
  return (
    <Dialog open={open}>
      <DialogTitle>
        {title}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <strong>{textContent}</strong>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => onCancel && onCancel()}
          color="primary"
          variant="outlined"
          autoFocus
        >
          Cancelar
        </Button>

        <Button
          onClick={() => onConfirm && onConfirm()}
          color="primary"
          variant="contained"
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
}


AlertConfirm.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  textContent: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}