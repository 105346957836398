


class DrawerHelper {
  static drawDeleteSticker = (ctx, xfrom, yfrom, w, text) => {

    const fromX = text.x + text.squareW - Math.floor(xfrom / text.scale);
    const fromY = text.y + Math.floor(yfrom / text.scale);
    const width = Math.floor(w / text.scale);

    ctx.setLineDash([]);
    ctx.beginPath();
    ctx.strokeStyle = 'black';
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(fromX + width, fromY + width);
    ctx.moveTo(fromX + width, fromY);
    ctx.lineTo(fromX, fromY + width);
    ctx.stroke();

    ctx.beginPath();
    ctx.stroke();
  }

  static drawCornerArrow = (ctx, xfrom, yfrom, l, text) => {
    const fromX = text.x + text.squareW - Math.floor(xfrom / text.scale)
    const fromY = text.y + Math.floor(yfrom / text.scale)
    const length = Math.floor(l / text.scale)

    const toY = fromY - length;
    const toX = fromX + length;
    const headlen = Math.floor(5 / text.scale); // Personaliza la longitud de la línea de flecha
    const theta = 45; // Personaliza el ángulo entre la línea de flecha y la línea recta, creo que 45 ° es lo correcto
    let arrowX = 0;
    let arrowY = 0; // Las coordenadas del punto final de la línea de flecha
    // Calcula cada ángulo y las coordenadas del punto final de la flecha correspondiente
    const angle = (Math.atan2(fromY - toY, fromX - toX) * 180) / Math.PI;
    const angle1 = ((angle + theta) * Math.PI) / 180;
    const angle2 = ((angle - theta) * Math.PI) / 180;
    const topX = headlen * Math.cos(angle1);
    const topY = headlen * Math.sin(angle1);
    const botX = headlen * Math.cos(angle2);
    const botY = headlen * Math.sin(angle2);
    ctx.beginPath();
    // Dibuja una línea recta
    ctx.moveTo(fromX + Math.floor(2 / text.scale), fromY - Math.floor(2 / text.scale));
    ctx.lineTo(toX, toY);

    arrowX = toX + topX;
    arrowY = toY + topY;
    // Dibuja la línea de flecha inferior
    ctx.moveTo(arrowX, arrowY);
    ctx.lineTo(toX, toY);

    arrowX = toX + botX;
    arrowY = toY + botY;
    // Dibuja la línea de flecha superior
    ctx.lineTo(arrowX, arrowY);

    ctx.setLineDash([]);
    ctx.strokeStyle = 'black';
    ctx.stroke();
  }

  static drawInferiorArrow = (ctx, fromX, fromY, l, text) => {

    const length = Math.floor(l / text.scale)
    const toY = fromY + length;
    const toX = fromX - length;
    const headlen = Math.floor(5 / text.scale); // Personaliza la longitud de la línea de flecha
    const theta = 45; // Personaliza el ángulo entre la línea de flecha y la línea recta, creo que 45 ° es lo correcto
    let arrowX = 0;
    let arrowY = 0; // Las coordenadas del punto final de la línea de flecha
    // Calcula cada ángulo y las coordenadas del punto final de la flecha correspondiente
    const angle = (Math.atan2(fromY - toY, fromX - toX) * 180) / Math.PI;
    const angle1 = ((angle + theta) * Math.PI) / 180;
    const angle2 = ((angle - theta) * Math.PI) / 180;
    const topX = headlen * Math.cos(angle1);
    const topY = headlen * Math.sin(angle1);
    const botX = headlen * Math.cos(angle2);
    const botY = headlen * Math.sin(angle2);
    ctx.beginPath();
    // Dibuja una línea recta
    ctx.moveTo(fromX, fromY);

    arrowX = toX + topX;
    arrowY = toY + topY;
    // Dibuja la línea de flecha inferior
    ctx.moveTo(arrowX, arrowY);
    ctx.lineTo(toX, toY);

    arrowX = toX + botX;
    arrowY = toY + botY;
    // Dibuja la línea de flecha superior
    ctx.lineTo(arrowX, arrowY);

    ctx.setLineDash([]);
    ctx.strokeStyle = 'black';
    ctx.stroke();
  }

  static drawLineArrow = (ctx, xfrom, yfrom, xto, yto, color, text) => {

    const fromX = text.x + Math.floor(xfrom / text.scale)
    const fromY = text.y + text.squareH - Math.floor(yfrom / text.scale)
    const toX = text.x + Math.floor(xto / text.scale)
    const toY = text.y + text.squareH - Math.floor(yto / text.scale)

    const headlen = Math.floor(8 / text.scale); // Personaliza la longitud de la línea de flecha
    const theta = 45; // Personaliza el ángulo entre la línea de flecha y la línea recta, creo que 45 ° es lo correcto
    let arrowX = 0;
    let arrowY = 0; // Las coordenadas del punto final de la línea de flecha
    // Calcula cada ángulo y las coordenadas del punto final de la flecha correspondiente
    const angle = (Math.atan2(fromY - toY, fromX - toX) * 180) / Math.PI;
    const angle1 = ((angle + theta) * Math.PI) / 180;
    const angle2 = ((angle - theta) * Math.PI) / 180;
    const topX = headlen * Math.cos(angle1);
    const topY = headlen * Math.sin(angle1);
    const botX = headlen * Math.cos(angle2);
    const botY = headlen * Math.sin(angle2);
    ctx.beginPath();
    // Dibuja una línea recta
    ctx.moveTo(fromX, fromY);
    ctx.lineTo(toX, toY);
    ctx.lineWidth = Math.floor(2 / text.scale);
    ctx.setLineDash([]);
    arrowX = toX + topX;
    arrowY = toY + topY;
    // Dibuja la línea de flecha superior
    ctx.moveTo(arrowX, arrowY);
    ctx.lineTo(toX, toY);

    arrowX = toX + botX;
    arrowY = toY + botY;
    // Dibuja la línea de flecha inferior
    ctx.lineTo(arrowX, arrowY);

    ctx.setLineDash([]);
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.lineWidth = Math.floor(1 / text.scale);
  }

  static drawDeleteMessage = (ctx, deleteMessage) => {
    const { x, y, scale, zoom } = deleteMessage

    const posX = (x - 10 * zoom) / scale
    const posY = (y - 25 * zoom) / scale
    const width = (45 * zoom / scale)
    const height = (15 * zoom / scale)

    ctx.strokeStyle = 'black';
    ctx.strokeRect(posX, posY, width, height);
    ctx.fillStyle = "white";
    ctx.fillRect(posX, posY, width, height);

    ctx.fillStyle = "black";
    ctx.font = '16px verdana';
    ctx.fillText("Eliminar", (x - 8 * zoom) / scale, (y - 15 * zoom) / scale)
  }

  static checkInDeleteSticker = (x, y, text) => {
    const { scale, zoom } = text;
    const initPointX = (text.x * scale + text.squareW * scale - 30) * zoom;
    const initPointY = (text.y * scale + 1) * zoom;
    const posX = x >= initPointX && x <= initPointX + 9 * zoom;
    const posY = y >= initPointY && y <= initPointY + 9 * zoom;
    return posX && posY;
  }

  static checkInRightBorder = (x, y, text) => {
    const { scale, zoom } = text;
    const initPointX = (text.x + text.squareW) * scale * zoom;
    const initPointY = text.y * scale * zoom;
    const posX = x >= (initPointX - 14 * zoom) && x <= initPointX;
    const posY = y >= initPointY && y <= (initPointY + 14 * zoom);
    return posX && posY;
  }

  static textHit = (x, y, text) => {
    const { scale, zoom } = text;
    const posX = x >= Math.floor(text.x * scale * zoom) && x <= Math.ceil((text.x + text.squareW) * scale * zoom);
    const posY = y >= Math.floor(text.y * scale * zoom) && y <= Math.ceil((text.y + text.squareH) * scale * zoom);
    return posX && posY;
  }

  static drawSquare = (ctx, text) => {
    ctx.beginPath()
    ctx.setLineDash([2, 3]);
    ctx.strokeStyle = 'red';
    ctx.strokeRect(text.x, text.y, text.squareW, text.squareH);

    ctx.fillStyle = 'yellow';
    ctx.fillRect(text.x, text.y, text.squareW, text.titleH);


    ctx.fillStyle = 'red';
    ctx.font = '18px verdana';
    ctx.fillText('Arrastrar y soltar', text.x, text.y + Math.floor(10 / text.scale), text.squareW);

    this.drawLineArrow(ctx, 20, 20, 3, 3, 'red', text);
    this.drawDeleteSticker(ctx, 30, 2, 11, text);
    this.drawCornerArrow(ctx, 14, 14, 12, text);
    this.drawInferiorArrow(ctx, text.x + text.squareW, text.y, 12, text);

    ctx.fillStyle = 'black';

    ctx.font = '20px verdana';
    const nameStickerInit = Math.floor(25 / text.scale)

    ctx.fillText(text.text, text.x + nameStickerInit, text.y + text.squareH - Math.floor(5 / text.scale), text.squareW - nameStickerInit);
  }

}

export default DrawerHelper