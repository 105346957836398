import clsx from 'clsx';
import { TableContainer, TableHead, TableRow, TableCell, Table, TableBody, makeStyles, IconButton } from '@material-ui/core'
import FolderIcon from '@material-ui/icons/Folder';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LockIcon from "@material-ui/icons/Lock"
import PropTypes from 'prop-types';
import { DocFileSelected } from '../../../icons/DocFileSelected';
import { timeAgo } from '../../../core/utils';


const useStyles = makeStyles((theme) => ({
  tableRoot: {
    borderCollapse: 'revert'
  },
  tableRowSelected: {
    backgroundColor: '#5C7BF0 !important',
    '& td': {
      color: 'white',
      '& h3': {
        color: 'white'
      }
    }
  },
  nameFileCell: {
    display: 'flex',
    alignItems: 'center'
  },
  nameFile: {
    marginLeft: '10px'
  },
  iconFolderRoot: {
    fontSize: '2.5rem',
    //cursor: 'pointer'
  },
  iconFolderSelected: {
    color: 'white !important'
  },
  cellItem: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px'
  },
  tableContainer: {
    maxHeight: 400
  },
  rowDisabled: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '& h3': {
      color: 'gray',
    },
    '& svg': {
      color: '#f44336',
    },
    '& td': {
      color: 'gray',
    }
  }
}));



export const TableFolder = (props) => {
  const classes = useStyles()
  const {
    files,
    onClickItem,
    itemSelected,
    onDoubleClick,
    isVaults = false,
    headCells, onClickOptions,
  } = props;


  return (
    <TableContainer className={classes.tableContainer}>
      <Table
        aria-labelledby="tableTitle"
        size={'small'}
        aria-label="enhanced table"
        padding="none"
        className={classes.tableRoot}
        stickyHeader
      >
        <TableHead>
          <TableRow>
            {headCells.map((cell, index) => (
              <TableCell
                key={index}
                align={'left'}
                padding={'normal'}
              >
                {cell}
              </TableCell>
            ))}

          </TableRow>
        </TableHead>

        <TableBody>
          {files.map((row, index) => {
            const isSelected = itemSelected ? (row.id === itemSelected.id) : false
            const isDisabled = row.isSuccessCloud !== undefined ? row.isSuccessCloud : true

            return (
              <TableRow
                hover
                onClick={() => isDisabled ? onClickItem(row) : {}}
                onDoubleClick={(e) => onDoubleClick(e, row)}
                role="checkbox"
                aria-checked={isSelected}
                tabIndex={-1}
                key={index}
                selected={isSelected}
                className={clsx(classes.tableRow, {
                  [classes.tableRowSelected]: isSelected,
                  [classes.rowDisabled]: !isDisabled
                })}
              >
                <TableCell padding={'normal'} className={classes.nameFileCell}>
                  <div>
                    <LockIcon
                      className={clsx(classes.iconFolderRoot, {
                        [classes.iconFolderSelected]: isSelected
                      })}
                      style={{ fontSize: "26px" }}
                      color='primary'
                    />
                  </div>
                  <div className={classes.nameFile}>
                    <h3>{row.name}</h3>
                  </div>
                </TableCell>
                <TableCell
                  align="justify"
                  padding={'normal'}
                  className={classes.cellItem}>
                  {row?.createdBy?.completeName ?? (row.author ? row.author : 'Desconocido')}
                </TableCell>
                <TableCell
                  align="justify"
                  padding={'normal'}
                  className={classes.cellItem}>
                  {row.lastUpdated ?? timeAgo.format((new Date(row.lastModified)))}
                </TableCell>

                {
                  !isVaults && (
                    <TableCell>
                      <IconButton
                        onClick={(event) => onClickOptions(event, row)}
                      >
                        <MoreVertIcon />
                      </IconButton>

                    </TableCell>
                  )
                }
              </TableRow>
            )
          })
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}


TableFolder.propTypes = {
  onClickItem: PropTypes.func.isRequired,
  files: PropTypes.array,
  headCells: PropTypes.array.isRequired,
  itemSelected: PropTypes.any
}