import React, { useEffect, useState } from 'react'
import Header from '../dashboard/header'
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import { IconButton } from '@material-ui/core'
import NotificationsIcon from "@material-ui/icons/Notifications";
import EventIcon from "@material-ui/icons/Event";
import { SearchBar } from './documents-information/SearchBar';
import { TableInformation } from './documents-information/TableInformation';
import { getPendingDocuments } from '../../core/http/functionRequests';
import DocumentReview from '../vault/document-review';
import { CalendarHttp } from '../../core/http/calendar.http';
import { FormActivityNative } from '../control/calendar/forms/form-activity-native';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from "axeleratum-sgc-frontend-library";
import { DialogFilter } from './documents-information/DialogFilter';
// import { getCurrentUser } from '../../core/helpers';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { DialogSignatureStatus } from '../dialogs/activities/DialogSignatureStatus';
import { tokenExpired } from '../../core/utils';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../core/actions';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const types = {
  "Revisión": "revision",
  "Firma": "firm",
}

export const DocumentsInformation = () => {
  const classes = useStyles();
  const calendarHttp = new CalendarHttp();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const [headCells, setHeadCells] = useState([]);
  const [stableData, setStableData] = useState([]);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [dataInSearch, setDataInSearch] = useState(false);
  const [firmDetail, setFirmDetail] = useState(null);
  const [reviewDetail, setReviewDetail] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [searchDetail, setSearchDetail] = useState(false);
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [activityDetail, setActivityDetail] = useState(null);
  const [openActivityEdit, setOpenActivityEdit] = useState(false);
  const [availableVaults, setAvailableVaults] = useState([]);
  const [searchName, setSearchName] = useState("");
  const [currentFilters, setCurrentFilters] = useState({
    vaults: [],
    involved: false,
    type: "Todos"
  })
  const [alert, setAlert] = useState({
    openAlert: false,
    message: "",
    typeMessage: "",
  });

  const applyFilters = (filters) => {
    let newData = [...stableData];
    if (filters.vaults.length > 0) {
      const vaults = filters.vaults
      newData = newData.filter(activity => vaults.includes(activity.vault));
    }

    if (filters.involved) {
      newData = newData.filter(activity => activity.involved);
    }

    if (filters.type !== "Todos") {
      newData = newData.filter(activity => activity.activity === types[filters.type]);
    }

    return newData;
  }

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchName(query);
    const newData = applyFilters(currentFilters)
    const filteredDocumentName = newData.filter(row =>
      row.documentName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    setData(filteredDocumentName);
  }

  const handleFilters = (filters) => {
    setSearchName("");
    setCurrentFilters(filters);
    let newData = applyFilters(filters);
    setData(newData);
    setShowFilter(false);
  }

  const handleCloseFilters = () => {
    setShowFilter(false);
    setSearchName("");
    setCurrentFilters({
      vaults: [],
      involved: false,
      type: "Todos"
    });
    setData(stableData);
  }

  const getSignatureDetail = (documentId) => {
    
    if(isLoggedIn && tokenExpired()){
      dispatch(authActions.userLoggedOut())
      return
    }

    const success = (activity) => {
      setFirmDetail(activity);
      setSearchDetail(false);
      setShowActivityModal(true);
    }
    const error = (err) => {
      setSearchDetail(false);
      console.error(err);
      setAlert({
        openAlert: true,
        message: "No se pudo obtener informacion del detalle",
        typeMessage: "error",
      });
    }
    calendarHttp.getActivityTypeByDocumentId(documentId, "firm", success, error)
  }

  const handleClickDetail = (document) => {
    setSearchDetail(true);
    if (document.activity === "firm") {
      getSignatureDetail(document.documentId);
    } else {
      setReviewDetail({ id: document.documentId, name: document.documentName });
    }
  }

  const handleCloseReview = () => {
    setShowActivityModal(null);
    setReviewDetail(null);
  }

  const handleFinishRequestReview = () => {
    setShowActivityModal(true);
    setSearchDetail(false);
  }

  const handleExitFirmDetail = () => {
    setFirmDetail(null);
    setShowActivityModal(false);
  }

  const handleSubmitEdit = () => {
    setActivityDetail(null);
    setOpenActivityEdit(false);
  }

  const handleFailGetReview = () => {
    setShowActivityModal(true);
    setSearchDetail(false);
    setAlert({
      openAlert: true,
      message: "No se pudo obtener informacion del detalle de revision",
      typeMessage: "error",
    })
  }

  const getVaults = (data) => {
    return [...new Set(data.map(el => el.vault))]
  }

  const getTable = async () => {
    try {
      const request = await getPendingDocuments();
      setData(request.rows);
      setStableData(request.rows);
      setHeadCells(request.columns);
      setAvailableVaults(getVaults(request.rows));
      if (request.rows.length > 0) setDataInSearch(true);
    } catch (error) {
      console.error(error);
      setAlert({
        openAlert: true,
        message: "Hubo un error conectando con el servidor",
        typeMessage: "error",
      });
    } finally {
      setLoadingData(false);
    }
  }

  useEffect(() => {
    if(isLoggedIn && tokenExpired()){
      dispatch(authActions.userLoggedOut())
      return
    }
    getTable();
  }, []);

  return (
    <div>
      <Header
        titleRight="Documentos en Revisión o Firma"
        actions={[
          <IconButton variant="outlined">
            <NotificationsIcon color="primary" />
          </IconButton>,
          <IconButton variant="outlined">
            <EventIcon color="primary" />
          </IconButton>,
        ]}
      />
      {
        !loadingData && !dataInSearch ? (
          <div>
            No hay actividades pendientes
          </div>
        ) : null
      }
      {
        dataInSearch ? (
          <>
            <SearchBar
              handleSearch={handleSearch}
              openFilter={() => setShowFilter(true)}
              valueName={searchName}
            />
            <TableInformation
              headCells={headCells}
              data={data}
              handleClickDetail={handleClickDetail}
            />
          </>
        ) : null
      }
      {
        !!reviewDetail ? (
          <DocumentReview
            immediatelyRevision={false}
            openDialog={showActivityModal}
            onClose={handleCloseReview}
            closeModal={handleCloseReview}
            document={reviewDetail}
            finishRequest={handleFinishRequestReview}
            onFailRequest={handleFailGetReview}
          />
        ) : null
      }

      {
        (!!firmDetail && showActivityModal) ? (
          <DialogSignatureStatus
            open={showActivityModal}
            setOpen={setShowActivityModal}
            item={firmDetail}
            onExitDetail={handleExitFirmDetail}
            onUpdate={() => getSignatureDetail(firmDetail.documentId)}
          />
        ) : null
      }

      {
        !!activityDetail && openActivityEdit ? (
          <FormActivityNative
            open={openActivityEdit}
            load={false}
            newOrUpdate={true}
            id={activityDetail.id}
            type={activityDetail.type}
            name={activityDetail.name}
            description={activityDetail.description}
            tags={
              activityDetail.tags
                ? activityDetail.tags.map((item) => ({
                  value: item,
                  label: item,
                }))
                : []
            }
            limitDate={activityDetail.date}
            hour={activityDetail.hour}
            recordatory={activityDetail.recordatory}
            document={{
              file: {
                documentId: firmDetail.documentId,
                name: firmDetail.documentName,
                users: []
              },
              name: firmDetail.documentName,
              type: "cloud",
              withOrder: false,
              stickersValue: null,
            }}
            onSubmit={(activity) => handleSubmitEdit(activity)}
            onCancel={() => {
              setOpenActivityEdit(false);
              setActivityDetail(null);
            }

            }
          />
        ) : null
      }

      <DialogFilter
        openFilters={showFilter}
        availableVaults={availableVaults}
        handleClose={handleCloseFilters}
        currentValues={currentFilters}
        handleFilter={handleFilters}
      />
      <Alert
        open={alert.openAlert}
        title={alert.message}
        type={alert.typeMessage}
        onConfirm={() => setAlert({ openAlert: false, message: "", typeMessage: "", })}
      />
      <Backdrop className={classes.backdrop} open={searchDetail || loadingData}>
        <CircularProgress />
      </Backdrop>
    </div>
  )
}
