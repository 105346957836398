import React from 'react';
import { Dialog, Grid, Button } from '@material-ui/core';
import { isMobile, isMobileOnly, RenderTextField } from 'axeleratum-sgc-frontend-library';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

const DialogFormFolder = (props) => {
  const { openDialog, submitActions, onCancel, handleSubmit, typeForm, folderName } = props;

  return (
    <Dialog className={isMobile ? '' : 'w-50-dialog'} open={openDialog}>
      <form className={isMobileOnly ? 'p-3' : 'p-5'} onSubmit={handleSubmit(submitActions)}>
        <Grid container>
          <Grid item xs={12}>
            <h3>{typeForm === 'create' ? 'Agregar Folder' : 'Editar Folder'}</h3>
            <Field
              required
              label="Nombre del folder"
              name="name"
              component={RenderTextField}
            />
          </Grid>

          <Grid className="mt-3" container justify="flex-end">
            {
              isMobile ?
                <React.Fragment>
                  <Grid item >
                    <Button
                      type="button"
                      variant="outlined"
                      color="secondary"
                      onClick={() => onCancel()}
                    >
                      Cancelar
                    </Button>
                  </Grid>

                  <Grid item >
                    <Button
                      className="ml-2"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!folderName}
                    >
                      {typeForm === 'create' ? 'Crear Folder' : 'Guardar'}
                    </Button>
                  </Grid>
                </React.Fragment>
                :
                <React.Fragment>
                  <Button
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={() => onCancel()}
                  >
                    Cancelar
                  </Button>

                  <Button
                    className="ml-2"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!folderName}
                  >
                    {typeForm === 'create' ? 'Crear Folder' : 'Guardar'}
                  </Button>
                </React.Fragment>
            }
          </Grid>

        </Grid>
      </form>
    </Dialog>
  )
}

const validate = (values) => {
  const errors = {
    name: ''
  }
  console.log({ values });

  if (!values.name || !values.name.trim()) {
    errors.name = 'El nombre del folder es requerido';
  }

  if (/["*:<>?/\\|.]/.test(values.name)) {
    errors.name = `El nombre no debe contener estos caracteres especiales:  " * : < > ? / \\ |`;
  }

  // if (values.name) {
  //   if (values.name.length > 30) {
  //     errors.name = 'El nombre del folder no puede ser mayor a 30 caracteres';
  //   }
  // }

  return errors;
}

let MainComponent = reduxForm({
  form: 'DialogFormFolder',
  validate
})(DialogFormFolder);


const selector = formValueSelector('DialogFormFolder');

MainComponent = connect(state => {
  const folderName = selector(state, 'name')
  return { folderName }
})(MainComponent);

export default MainComponent;
