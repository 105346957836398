import { combineReducers } from 'redux';
import { reducer } from 'redux-form';

import { authReducer } from './auth.reducer';
import { alertReducer } from './alert.reducer';
import { userReducer } from './user.reducer';
import { permissionsReducer } from './permissions.reducer';
import { folderReducer } from './folder.reducer';
import { activityReducer } from './activity.reducer';
import { foldersSearchReducer } from './foldersSearch.reducer';
import { vaultReducer } from './vault.reducer';
import { fileSystemReducer } from './fileSystem.reducer';

const rootReducer = combineReducers({
  authReducer,
  alertReducer,
  userReducer,
  permissionsReducer,
  folderReducer,
  activityReducer,
  foldersSearchReducer,
  vaultReducer,
  fileSystemReducer,
  form: reducer,
})

export default rootReducer
